import flagES from 'images/flag-spain.png';
import flagPT from 'images/flag-portugal.png';
import flagEN from 'images/flag-uk.png';

export const getFlagFromLanguage = (lng: string) => {
  switch (lng) {
    case 'en':
      return flagEN;
    case 'es':
      return flagES;
    case 'pt':
      return flagPT;
    default:
      break;
  }
};
