import React, { useState } from 'react';
import styled from 'styled-components';
import { breakpoints } from 'theme';

import safebrokLogo from 'images/safebrok-logo-simple.png';
import openMenuButton from 'images/mobileNav-open.png';
import closeMenuButton from 'images/mobileNav-close.png';

import WebsiteToggleDropdown from './components/WebsiteToggleDropdown';
import SidePanel from './components/SidePanel';
import LanguageMenu from './components/LanguageMenu';
import { Link } from 'gatsby-plugin-react-i18next';

const Navigation = styled.nav`
  display: none;

  @media (max-width: ${breakpoints.tablet}) {
    background-color: #000;
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: fixed;
    padding: 14px 20px 12px 20px;
    z-index: 3;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const ToggleSidePanelButton = styled.button<{ lngMenuIsHalfOpen: boolean }>`
  display: ${({ lngMenuIsHalfOpen }) => (lngMenuIsHalfOpen ? 'none' : 'block')};
  background-color: transparent;
  border: none;
  z-index: 5;
`;

const MobileNav = () => {
  const [sideMenuIsOpen, setSideMenuIsOpen] = useState(false);
  const [lngMenuIsHalfOpen, setLngMenuIsHalfOpen] = useState(false);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  const handleSideMenuButtonClick = () => {
    if (dropdownIsOpen) {
      setDropdownIsOpen(false);
    }

    setSideMenuIsOpen((previousState) => !previousState);
  };

  const handleLanguageSelection = () => {
    setLngMenuIsHalfOpen(false);
    setSideMenuIsOpen(false);
  };

  return (
    <Navigation>
      <FlexWrapper>
        <Link to="/">
          <img src={safebrokLogo} alt="Safebrok logo" />
        </Link>
        <WebsiteToggleDropdown
          dropdownIsOpen={dropdownIsOpen}
          setDropdownIsOpen={setDropdownIsOpen}
          sideMenuIsOpen={sideMenuIsOpen}
        />
      </FlexWrapper>
      <ToggleSidePanelButton
        onClick={handleSideMenuButtonClick}
        lngMenuIsHalfOpen={lngMenuIsHalfOpen}
      >
        <img
          src={sideMenuIsOpen ? closeMenuButton : openMenuButton}
          alt="Menu"
        />
      </ToggleSidePanelButton>
      <SidePanel
        isOpen={sideMenuIsOpen}
        setLngMenuIsHalfOpen={setLngMenuIsHalfOpen}
        lngMenuIsHalfOpen={lngMenuIsHalfOpen}
        setSideMenuIsOpen={setSideMenuIsOpen}
      />
      <LanguageMenu
        lngMenuIsHalfOpen={lngMenuIsHalfOpen}
        onLanguageSelection={handleLanguageSelection}
      />
    </Navigation>
  );
};

export default MobileNav;
