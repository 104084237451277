import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from 'theme';

import 'i18n/config';
import FlagProvider from '@unleash/proxy-client-react';

const config = {
  url: process.env.GATSBY_UNLEASH_URL,
  clientKey: process.env.GATSBY_UNLEASH_CLIENT_KEY,
  refreshInterval: 15,
  appName: process.env.GATSBY_UNLEASH_APP_NAME,
  environment: process.env.GATSBY_UNLEASH_ENVIRONMENT,
};

const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <FlagProvider config={config}>{element}</FlagProvider>
    </ThemeProvider>
  );
};

export default wrapRootElement;
