import React, { useState, useEffect } from 'react';
import { useLocation } from '@reach/router';
import 'styles/global.css';

import Footer from 'components/Footer';
import MobileNav from 'components/MobileNavigation';
import FreshchatWidget from 'components/FreshchatWidget';
import { supportedLanguages } from '../../../constants/supportedLanguages';
import CookieBanner from 'components/CookieBanner';
import { getCookieConsentValue } from 'react-cookie-consent';
import Cookies from 'js-cookie';

interface LayoutProps {
  children: React.ReactNode;
}

const googleAnalyticsId = process.env.GATSBY_GOOGLE_ANALYTICS_ID;
const googleTagManagerId = process.env.GATSBY_GOOGLE_TAG_MANAGER_ID;

const Layout = ({ children }: LayoutProps) => {
  const location = useLocation();
  const [hasConsentValue, setConsentValue] = useState(false);

  useEffect(() => {
    const language = window.navigator.language.slice(0, 2);

    if (supportedLanguages.includes(language)) {
      window.localStorage.setItem('language', language);
    } else {
      window.localStorage.setItem('language', 'es');
    }
  }, []);

  const handleCookieConsent = (value: boolean) => {
    setConsentValue(value);
  };

  useEffect(() => {
    const consentValue = getCookieConsentValue();
    setConsentValue(consentValue === 'true');
  }, []);

  function loadGooleAnalytics() {
    const gtmScript = document.createElement('script');
    gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${googleTagManagerId}');`;
    document.head.insertBefore(gtmScript, document.head.firstChild);

    const gtmNoscript = document.createElement('noscript');
    gtmNoscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.insertBefore(gtmNoscript, document.body.firstChild);
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];

    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', `${googleAnalyticsId}`, {
      page_path: window.location.pathname,
    } as any);
  }

  useEffect(() => {
    if (!hasConsentValue) {
      const googleAnalyticsCookies = Object.keys(Cookies.get()).filter(
        (cookies) => cookies.startsWith('_ga'),
      );
      googleAnalyticsCookies.forEach((cookie) => Cookies.remove(cookie));
    }
  }, [hasConsentValue]);

  useEffect(() => {
    if (hasConsentValue) {
      loadGooleAnalytics();
    }
  }, [hasConsentValue]);

  const isNotFoundPage = () => {
    switch (location.pathname) {
      case '/en/not-found':
      case '/es/not-found':
      case '/pt/not-found':
      case '/en/something-went-wrong':
      case '/es/something-went-wrong':
      case '/pt/something-went-wrong':
        return true;
      default:
        return false;
    }
  };

  const shouldShowFooter = !isNotFoundPage();

  return (
    <>
      <MobileNav />
      {children}
      {shouldShowFooter && <Footer />}
      <CookieBanner onConsent={handleCookieConsent} />
      {hasConsentValue ? <FreshchatWidget /> : null}
    </>
  );
};

export default Layout;
