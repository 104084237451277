import flagSpain from 'images/flag-spain-wavy.png';
import flagPortugal from 'images/flag-portugal-wavy.png';
import flagUk from 'images/flag-uk-wavy.png';

export const MAXIMUM_CHARACTERS_TEXT_AREA = 300;

export const NAVIGATION_ITEMS = [
  {
    key: 'home.atf.navigation_tabs.home',
    href: '/',
  },
  {
    key: 'home.atf.navigation_tabs.services',
    href: '/services',
  },
  {
    key: 'home.atf.navigation_tabs.about_us',
    href: '/about',
  },
  {
    key: 'home.atf.navigation_tabs.careers',
    href: '/careers',
  },
  {
    key: 'home.atf.navigation_tabs.insights',
    href: '/insights',
  },
  {
    key: 'home.atf.navigation_tabs.contacts',
    href: '/contact',
  },
];

export const AVAILABLE_LOKALISE_LANGUAGES = [
  {
    abbreviation: 'en',
    language: 'English', //TODO: replace with lokalise key
    flagIcon: flagUk,
  },
  {
    abbreviation: 'es',
    language: 'Español', //TODO: replace with lokalise key
    flagIcon: flagSpain,
  },
  {
    abbreviation: 'pt',
    language: 'Português', //TODO: replace with lokalise key
    flagIcon: flagPortugal,
  },
];
