import { rem } from 'polished';
import React from 'react';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import { NAVIGATION_ITEMS } from 'utils/constants';
import LanguageDisplay from './LanguageDisplay';

const Container = styled.div<{ isOpen: boolean; lngMenuIsHalfOpen: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  background-color: #000;
  width: ${({ lngMenuIsHalfOpen }) => (lngMenuIsHalfOpen ? '100%' : '70%')};

  height: 100%;
  position: fixed;
  z-index: 3;
  top: 60px;
  right: 0;
  text-align: right;

  padding-right: 24px;
  padding-bottom: 40px;

  transition: all 200ms ease-in-out;
  transform: ${({ isOpen }) =>
    isOpen ? 'translateX(0%)' : 'translateX(100%)'};
  transform: ${({ lngMenuIsHalfOpen }) =>
    lngMenuIsHalfOpen && 'translateX(-124px)'};/
`;

const ItemList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 32px;
  background-color: #000;
`;

const Item = styled.li`
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem(24)};
  line-height: ${rem(37)};
  color: #fff;

  .active {
    font-weight: ${(props) => props.theme.fontWeights.semibold};
    font-size: ${rem(28)};
    line-height: ${rem(32)};
  }
`;

interface SidePanelProps {
  isOpen: boolean;
  setLngMenuIsHalfOpen: (bool: boolean) => void;
  lngMenuIsHalfOpen: boolean;
  setSideMenuIsOpen: (bool: boolean) => void;
}

const SidePanel = ({
  isOpen,
  setLngMenuIsHalfOpen,
  lngMenuIsHalfOpen,
  setSideMenuIsOpen,
}: SidePanelProps) => {
  const { t } = useTranslation();

  const handlePanelContainerClick = () => {
    if (lngMenuIsHalfOpen) {
      setLngMenuIsHalfOpen(false);
    }
  };

  return (
    <Container
      onClick={handlePanelContainerClick}
      isOpen={isOpen}
      lngMenuIsHalfOpen={lngMenuIsHalfOpen}
    >
      <ItemList>
        {NAVIGATION_ITEMS.map((item) => (
          <Item>
            <Link
              to={item.href}
              activeClassName="active"
              onClick={() => setSideMenuIsOpen(false)}
            >
              {t(item.key)}
            </Link>
          </Item>
        ))}
      </ItemList>
      <LanguageDisplay
        lngMenuIsHalfOpen={lngMenuIsHalfOpen}
        setLngMenuIsHalfOpen={setLngMenuIsHalfOpen}
      />
    </Container>
  );
};

export default SidePanel;
