import { rem } from 'polished';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import dropdownArrow from 'images/dropdown-arrow.png';

const Container = styled.div`
  font-size: ${rem(14)};
  line-height: ${rem(21)};

  position: relative;
`;

const SelectedOption = styled.button<{ sideMenuIsOpen: boolean }>`
  width: 100%;
  background-color: #000;
  color: #fff;
  font-weight: ${(props) => props.theme.fontWeights.medium};

  padding-right: 20px;

  background-image: ${({ sideMenuIsOpen }) =>
    sideMenuIsOpen ? 'none' : `url(${dropdownArrow})`};
  background-repeat: no-repeat;
  background-position: center right;
`;

const OptionsList = styled.ul<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  gap: 24px;
  min-width: 170px;

  list-style: none;
  padding: 24px;
  background-color: #000;
  color: #fff;

  font-weight: ${(props) => props.theme.fontWeights.regular};

  transition: all 300ms ease-in-out;

  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};

  width: 100%;
  position: absolute;
  z-index: 2;

  .active {
    color: ${(props) => props.theme.brandPalette.primary};
    font-weight: ${(props) => props.theme.fontWeights.medium};
  }
`;

const Option = styled.li<{ isOpen: boolean }>`
  border-radius: 6px;
  color: #fff;
  pointer-events: ${({ isOpen }) => (isOpen ? 'initial' : 'none')};

  &:hover {
    cursor: pointer;
  }
`;

const OPTIONS = {
  seguros: { name: 'SafeBrok', href: 'https://www.safebrok.com' },
};

interface DropdownProps {
  sideMenuIsOpen: boolean;
  dropdownIsOpen: boolean;
  setDropdownIsOpen: (bool: boolean) => void;
}

const WebsiteToggleDropdown = ({
  sideMenuIsOpen,
  dropdownIsOpen,
  setDropdownIsOpen,
}: DropdownProps) => {
  const ref = useRef(null);

  const handleDropdownClick = () => {
    if (sideMenuIsOpen) {
      return;
    }
    setDropdownIsOpen(!dropdownIsOpen);
  };

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setDropdownIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <Container ref={ref}>
      <SelectedOption
        onClick={handleDropdownClick}
        sideMenuIsOpen={sideMenuIsOpen}
      >
        {OPTIONS.seguros.name}
      </SelectedOption>
      <OptionsList isOpen={dropdownIsOpen}>
        {Object.values(OPTIONS).map((option) => (
          <Option
            className={option === OPTIONS.seguros ? 'active' : ''}
            isOpen={dropdownIsOpen}
            key={option.name}
          >
            <a href={option === OPTIONS.seguros ? '#' : option.href}>
              {option.name}
            </a>
          </Option>
        ))}
      </OptionsList>
    </Container>
  );
};

export default WebsiteToggleDropdown;
