exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-en-blog-post-title-tsx": () => import("./../../../src/pages/en/blog/[postTitle].tsx" /* webpackChunkName: "component---src-pages-en-blog-post-title-tsx" */),
  "component---src-pages-es-blog-post-title-tsx": () => import("./../../../src/pages/es/blog/[postTitle].tsx" /* webpackChunkName: "component---src-pages-es-blog-post-title-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-insights-tsx": () => import("./../../../src/pages/insights.tsx" /* webpackChunkName: "component---src-pages-insights-tsx" */),
  "component---src-pages-not-found-tsx": () => import("./../../../src/pages/not-found.tsx" /* webpackChunkName: "component---src-pages-not-found-tsx" */),
  "component---src-pages-pt-blog-post-title-tsx": () => import("./../../../src/pages/pt/blog/[postTitle].tsx" /* webpackChunkName: "component---src-pages-pt-blog-post-title-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-something-went-wrong-tsx": () => import("./../../../src/pages/something-went-wrong.tsx" /* webpackChunkName: "component---src-pages-something-went-wrong-tsx" */),
  "component---src-templates-offer-content-tsx": () => import("./../../../src/templates/offerContent.tsx" /* webpackChunkName: "component---src-templates-offer-content-tsx" */)
}

