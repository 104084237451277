import { useCallback, useEffect } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import theme from 'theme';

const FreshchatWidget = () => {
  const { i18n } = useTranslation();
  const locale = i18n.language;

  const initFreshChat = useCallback(() => {
    window.fcWidget.init({
      token: process.env.GATSBY_FRESHCHAT_TOKEN,
      host: process.env.GATSBY_FRESHCHAT_HOST,
      locale,
      tags: ['safebrok seguros'],
      siteId: 'safebrok seguros',
      config: {
        headerProperty: {
          backgroundColor: theme.brandPalette.secondary,
          appName: 'Safebrok',
        },
      },
    });
  }, [locale]);

  const initialize = useCallback(
    (i: Document, t: string) => {
      var e;
      i.getElementById(t)
        ? initFreshChat()
        : (((e = i.createElement('script')).id = t),
          (e.async = !0),
          (e.src = `${process.env.GATSBY_FRESHCHAT_HOST}/js/widget.js`),
          (e.onload = initFreshChat),
          i.head.appendChild(e));
    },
    [initFreshChat],
  );

  function initiateCall() {
    initialize(document, 'Freshchat-js-sdk');
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      window.addEventListener
        ? window.addEventListener('load', initiateCall, !1)
        : window.attachEvent('load', initiateCall, !1);
      initiateCall();
    }

    return () => {
      isMounted = false;

      if (window.fcWidget) window.fcWidget.destroy();
    };
  }, [initialize]);

  return null;
};

export default FreshchatWidget;
